/*https://google-webfonts-helper.herokuapp.com/fonts/david-libre?subsets=hebrew,latin*/
/* amatic-sc-regular - latin_hebrew */
@font-face {
    font-family: "Amatic SC";
    font-style: normal;
    font-weight: 400;
    src: url("Amatic_SC/amatic-sc-v24-latin_hebrew-regular.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Amatic_SC/amatic-sc-v24-latin_hebrew-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Amatic_SC/amatic-sc-v24-latin_hebrew-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Amatic_SC/amatic-sc-v24-latin_hebrew-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("Amatic_SC/amatic-sc-v24-latin_hebrew-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Amatic_SC/amatic-sc-v24-latin_hebrew-regular.svg#AmaticSC")
            format("svg"); /* Legacy iOS */
}
/* amatic-sc-700 - latin_hebrew */
@font-face {
    font-family: "Amatic SC";
    font-style: normal;
    font-weight: 700;
    src: url("Amatic_SC/amatic-sc-v24-latin_hebrew-700.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Amatic_SC/amatic-sc-v24-latin_hebrew-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Amatic_SC/amatic-sc-v24-latin_hebrew-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Amatic_SC/amatic-sc-v24-latin_hebrew-700.woff") format("woff"),
        /* Modern Browsers */
            url("Amatic_SC/amatic-sc-v24-latin_hebrew-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Amatic_SC/amatic-sc-v24-latin_hebrew-700.svg#AmaticSC")
            format("svg"); /* Legacy iOS */
}

/* alef-regular - latin_hebrew */
@font-face {
    font-family: "Alef";
    font-style: normal;
    font-weight: 400;
    src: url("Alef/alef-v21-latin_hebrew-regular.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Alef/alef-v21-latin_hebrew-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Alef/alef-v21-latin_hebrew-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Alef/alef-v21-latin_hebrew-regular.woff") format("woff"),
        /* Modern Browsers */ url("Alef/alef-v21-latin_hebrew-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Alef/alef-v21-latin_hebrew-regular.svg#Alef") format("svg"); /* Legacy iOS */
}
/* alef-700 - latin_hebrew */
@font-face {
    font-family: "Alef";
    font-style: normal;
    font-weight: 700;
    src: url("Alef/alef-v21-latin_hebrew-700.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Alef/alef-v21-latin_hebrew-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Alef/alef-v21-latin_hebrew-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Alef/alef-v21-latin_hebrew-700.woff")
            format("woff"),
        /* Modern Browsers */ url("Alef/alef-v21-latin_hebrew-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Alef/alef-v21-latin_hebrew-700.svg#Alef") format("svg"); /* Legacy iOS */
}

/* assistant-200 - latin_hebrew */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 200;
    src: url("Assistant/assistant-v16-latin_hebrew-200.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Assistant/assistant-v16-latin_hebrew-200.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Assistant/assistant-v16-latin_hebrew-200.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-200.woff") format("woff"),
        /* Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-200.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Assistant/assistant-v16-latin_hebrew-200.svg#Assistant")
            format("svg"); /* Legacy iOS */
}
/* assistant-300 - latin_hebrew */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 300;
    src: url("Assistant/assistant-v16-latin_hebrew-300.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Assistant/assistant-v16-latin_hebrew-300.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Assistant/assistant-v16-latin_hebrew-300.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-300.woff") format("woff"),
        /* Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-300.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Assistant/assistant-v16-latin_hebrew-300.svg#Assistant")
            format("svg"); /* Legacy iOS */
}
/* assistant-regular - latin_hebrew */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 400;
    src: url("Assistant/assistant-v16-latin_hebrew-regular.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Assistant/assistant-v16-latin_hebrew-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Assistant/assistant-v16-latin_hebrew-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Assistant/assistant-v16-latin_hebrew-regular.svg#Assistant")
            format("svg"); /* Legacy iOS */
}
/* assistant-500 - latin_hebrew */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 500;
    src: url("Assistant/assistant-v16-latin_hebrew-500.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Assistant/assistant-v16-latin_hebrew-500.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Assistant/assistant-v16-latin_hebrew-500.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-500.woff") format("woff"),
        /* Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-500.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Assistant/assistant-v16-latin_hebrew-500.svg#Assistant")
            format("svg"); /* Legacy iOS */
}
/* assistant-600 - latin_hebrew */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 600;
    src: url("Assistant/assistant-v16-latin_hebrew-600.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Assistant/assistant-v16-latin_hebrew-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Assistant/assistant-v16-latin_hebrew-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-600.woff") format("woff"),
        /* Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Assistant/assistant-v16-latin_hebrew-600.svg#Assistant")
            format("svg"); /* Legacy iOS */
}
/* assistant-700 - latin_hebrew */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 700;
    src: url("Assistant/assistant-v16-latin_hebrew-700.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Assistant/assistant-v16-latin_hebrew-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Assistant/assistant-v16-latin_hebrew-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-700.woff") format("woff"),
        /* Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Assistant/assistant-v16-latin_hebrew-700.svg#Assistant")
            format("svg"); /* Legacy iOS */
}
/* assistant-800 - latin_hebrew */
@font-face {
    font-family: "Assistant";
    font-style: normal;
    font-weight: 800;
    src: url("Assistant/assistant-v16-latin_hebrew-800.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Assistant/assistant-v16-latin_hebrew-800.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Assistant/assistant-v16-latin_hebrew-800.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-800.woff") format("woff"),
        /* Modern Browsers */
            url("Assistant/assistant-v16-latin_hebrew-800.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Assistant/assistant-v16-latin_hebrew-800.svg#Assistant")
            format("svg"); /* Legacy iOS */
}

/* david-libre-regular - latin_hebrew */
@font-face {
    font-family: "David Libre";
    font-style: normal;
    font-weight: 400;
    src: url("David_Libre/david-libre-v12-latin_hebrew-regular.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("David_Libre/david-libre-v12-latin_hebrew-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("David_Libre/david-libre-v12-latin_hebrew-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("David_Libre/david-libre-v12-latin_hebrew-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("David_Libre/david-libre-v12-latin_hebrew-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("David_Libre/david-libre-v12-latin_hebrew-regular.svg#DavidLibre")
            format("svg"); /* Legacy iOS */
}
/* david-libre-500 - latin_hebrew */
@font-face {
    font-family: "David Libre";
    font-style: normal;
    font-weight: 500;
    src: url("David_Libre/david-libre-v12-latin_hebrew-500.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("David_Libre/david-libre-v12-latin_hebrew-500.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("David_Libre/david-libre-v12-latin_hebrew-500.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("David_Libre/david-libre-v12-latin_hebrew-500.woff")
            format("woff"),
        /* Modern Browsers */
            url("David_Libre/david-libre-v12-latin_hebrew-500.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("David_Libre/david-libre-v12-latin_hebrew-500.svg#DavidLibre")
            format("svg"); /* Legacy iOS */
}
/* david-libre-700 - latin_hebrew */
@font-face {
    font-family: "David Libre";
    font-style: normal;
    font-weight: 700;
    src: url("David_Libre/david-libre-v12-latin_hebrew-700.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("David_Libre/david-libre-v12-latin_hebrew-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("David_Libre/david-libre-v12-latin_hebrew-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("David_Libre/david-libre-v12-latin_hebrew-700.woff")
            format("woff"),
        /* Modern Browsers */
            url("David_Libre/david-libre-v12-latin_hebrew-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("David_Libre/david-libre-v12-latin_hebrew-700.svg#DavidLibre")
            format("svg"); /* Legacy iOS */
}

/* heebo-100 - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 100;
    src: url("Heebo/heebo-v20-latin_hebrew-100.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-100.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-100.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-100.woff")
            format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-100.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-100.svg#Heebo") format("svg"); /* Legacy iOS */
}
/* heebo-200 - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 200;
    src: url("Heebo/heebo-v20-latin_hebrew-200.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-200.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-200.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-200.woff")
            format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-200.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-200.svg#Heebo") format("svg"); /* Legacy iOS */
}
/* heebo-300 - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 300;
    src: url("Heebo/heebo-v20-latin_hebrew-300.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-300.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-300.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-300.woff")
            format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-300.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-300.svg#Heebo") format("svg"); /* Legacy iOS */
}
/* heebo-regular - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 400;
    src: url("Heebo/heebo-v20-latin_hebrew-regular.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Heebo/heebo-v20-latin_hebrew-regular.woff") format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-regular.svg#Heebo") format("svg"); /* Legacy iOS */
}
/* heebo-500 - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 500;
    src: url("Heebo/heebo-v20-latin_hebrew-500.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-500.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-500.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-500.woff")
            format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-500.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-500.svg#Heebo") format("svg"); /* Legacy iOS */
}
/* heebo-600 - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 600;
    src: url("Heebo/heebo-v20-latin_hebrew-600.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-600.woff")
            format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-600.svg#Heebo") format("svg"); /* Legacy iOS */
}
/* heebo-700 - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 700;
    src: url("Heebo/heebo-v20-latin_hebrew-700.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-700.woff")
            format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-700.svg#Heebo") format("svg"); /* Legacy iOS */
}
/* heebo-800 - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 800;
    src: url("Heebo/heebo-v20-latin_hebrew-800.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-800.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-800.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-800.woff")
            format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-800.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-800.svg#Heebo") format("svg"); /* Legacy iOS */
}
/* heebo-900 - latin_hebrew */
@font-face {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 900;
    src: url("Heebo/heebo-v20-latin_hebrew-900.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Heebo/heebo-v20-latin_hebrew-900.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Heebo/heebo-v20-latin_hebrew-900.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-900.woff")
            format("woff"),
        /* Modern Browsers */ url("Heebo/heebo-v20-latin_hebrew-900.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Heebo/heebo-v20-latin_hebrew-900.svg#Heebo") format("svg"); /* Legacy iOS */
}

/* lobster-regular - latin */
@font-face {
    font-family: "Lobster";
    font-style: normal;
    font-weight: 400;
    src: url("Lobster/lobster-v28-latin-regular.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Lobster/lobster-v28-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Lobster/lobster-v28-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Lobster/lobster-v28-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("Lobster/lobster-v28-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Lobster/lobster-v28-latin-regular.svg#Lobster") format("svg"); /* Legacy iOS */
}

/* pacifico-regular - latin */
@font-face {
    font-family: "Pacifico";
    font-style: normal;
    font-weight: 400;
    src: url("Pacifico/pacifico-v22-latin-regular.eot"); /* IE9 Compat Modes */
    src:
        local(""),
        url("Pacifico/pacifico-v22-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("Pacifico/pacifico-v22-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("Pacifico/pacifico-v22-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("Pacifico/pacifico-v22-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("Pacifico/pacifico-v22-latin-regular.svg#Pacifico")
            format("svg"); /* Legacy iOS */
}
