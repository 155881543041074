@font-face {
    font-family: "fontello";
    src: url("../font/fontello.eot?97525700");
    src:
        url("../font/fontello.eot?97525700#iefix") format("embedded-opentype"),
        url("../font/fontello.woff2?97525700") format("woff2"),
        url("../font/fontello.woff?97525700") format("woff"),
        url("../font/fontello.ttf?97525700") format("truetype"),
        url("../font/fontello.svg?97525700#fontello") format("svg");
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?97525700#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-down-open:before {
    content: "\e800";
} /* '' */
.icon-left-open:before {
    content: "\e801";
} /* '' */
.icon-right-open:before {
    content: "\e802";
} /* '' */
.icon-up-open:before {
    content: "\e803";
} /* '' */
.icon-chev-down:before {
    content: "\e804";
} /* '' */
.icon-padlock:before {
    content: "\e80d";
} /* '' */
.icon-padlockopen:before {
    content: "\e80e";
} /* '' */
.icon-background:before {
    content: "\e810";
} /* '' */
.icon-cloud:before {
    content: "\e811";
} /* '' */
.icon-align-bottom:before {
    content: "\e861";
} /* '' */
.icon-align-left:before {
    content: "\e863";
} /* '' */
.icon-align-right:before {
    content: "\e864";
} /* '' */
.icon-align-up:before {
    content: "\e865";
} /* '' */
.icon-align-center:before {
    content: "\e8f0";
} /* '' */
.icon-align-horizontal:before {
    content: "\e8f1";
} /* '' */
.icon-go-back:before {
    content: "\e8f5";
} /* '' */
.icon-go-backward:before {
    content: "\e8f6";
} /* '' */
.icon-go-up:before {
    content: "\e8f7";
} /* '' */
.icon-go-front:before {
    content: "\e8f8";
} /* '' */
.icon-line:before {
    content: "\e8fd";
} /* '' */
.icon-opacity:before {
    content: "\e909";
} /* '' */
.icon-text-stroke:before {
    content: "\e996";
} /* '' */
