@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
:root {
    /*Texts*/
    --main-color-text: black;
    --main-font-size: 1rem;
    --secondary-font-size: 0.9rem; /*16px*/
    --small-font-size: 0.8rem; /*14px*/
    --extra-small-font-size: 0.7rem; /*12px*/

    /*gap*/
    --line-gap: 0.25rem;
    --extra-small-gap: 0.28rem; /*5px*/
    --small-gap: 0.85rem; /*15px*/
    --medium-gap: 1.1rem; /*20px*/
    --large-gap: 1.95rem; /*35px*/
    --extra-large-gap: 2.75rem; /*50px*/
    --huge-gap: 5.5rem; /*100px*/

    --main-shadow: 0 0.33rem 0.33rem 0 rgba(0, 0, 0, 0.12);

    --main-filter-shadow: drop-shadow(0rem 0.31rem 0.97rem rgba(0, 0, 0, 0.1));

    --main-border-radius: 0.55rem; /*10px*/

    --top-bar-menu-height: 76px;
    --side-bar-width: 72px;
    --mobile-side-bar-height: 57px;
    --side-bar-fold-width: 326px;

    --overlay: rgb(0, 0, 0, 0.5);

    --white: #ffffff;
    --background-text-box: #f5f6f7;
    --snap-color: #b1dfda;

    /* --snap-color: black; */

    --main-border-color: #e7e8e9;
    --hover-color: #e3f3f2;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

strong,
.bold {
    font-weight: bold;
}

h1,
h2,
h3 {
    font-weight: bold;
}

html {
    box-sizing: border-box;
    font-size: 18px;
}

body {
    background-color: var(--white);
    margin: 0;
    direction: rtl;
    color: var(--main-color-text);
    user-select: none;
}
button {
    color: var(--main-color-text);
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

.relative {
    position: relative;
}

.ltr {
    direction: ltr;
}

.positionMiddle {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.left_0 {
    left: 0;
}
.right_0 {
    right: 0;
}

.item-dragged .mini-toolbar {
    opacity: 0 !important;
}

.hidden {
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
}
